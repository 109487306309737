import React from "react";
// import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-green-900 text-white py-4 mt-auto">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <div className="flex space-x-4">
          {/* <Link to="/privacy-policy" className="hover:underline">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="hover:underline">
            Terms of Service
          </Link> */}
        </div>
        <div>
          © {new Date().getFullYear()} Romsey Shooting Club. All rights
          reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
