import React, { useState } from "react";
import "./Header.css";

import logo from "../assets/images/rsc_logo_v1.png"; // Make sure to update the path to your logo

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <div className="header-top">
        <div className="logo-container">
          <img src={logo} alt="Romsey Shooting Club Logo" className="logo" />
          <span className="club-name">Romsey Shooting Club</span>
        </div>
        <button className="hamburger" onClick={toggleNav}>
          ☰
        </button>
        <nav className={`nav ${isNavOpen ? "nav-open" : ""}`}>
          <a href="/#about-us" className="nav-link">
            About Us
          </a>
          <a href="/#how-to-join" className="nav-link">
            How to Join
          </a>
          <a href="/#disciplines" className="nav-link">
            Disciplines
          </a>
          <a href="/secured/members.html" className="nav-link">
            Members
          </a>
          <a href="/#contact-us" className="contact-button">
            Contact Us
          </a>
        </nav>
      </div>
    </header>
  );
}

export default Header;
