// src/Views/HomePage.js
import React, { useState, useEffect } from "react";
import "./HomePage.css";
import Header from "./Header.js";
import forestImage1 from "../assets/images/IMG_5019.jpeg";
import forestImage2 from "../assets/images/IMG_5020.jpeg";
import forestImage3 from "../assets/images/IMG_5021.jpeg";
import forestImage4 from "../assets/images/IMG_5022.jpeg";
import forestImage5 from "../assets/images/IMG_5023.jpeg";
import Footer from "./Footer.js";
import Turnstile from "react-turnstile";

function HomePage() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    forestImage1,
    forestImage2,
    forestImage3,
    forestImage4,
    forestImage5,
  ];
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [message, setMessage] = useState({ text: "", type: "" });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!turnstileToken) {
      setMessage({
        text: "Please complete the Turnstile challenge",
        type: "error",
      });
      return;
    }

    setFormSubmitting(true);

    try {
      const formData = {
        name: e.target.elements[0].value,
        email: e.target.elements[1].value,
        message: e.target.elements[2].value,
        turnstileToken: turnstileToken,
      };

      const response = await fetch(
        "https://red-scene-104d.romsey-shooting-club.workers.dev",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      setMessage({ text: "Message sent successfully!", type: "success" });
      e.target.reset();
      setTurnstileToken(null);
    } catch (error) {
      console.error("Error sending message:", error);
      setMessage({
        text: "Failed to send message. Please try again later.",
        type: "error",
      });
    } finally {
      setFormSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header />
      <div className="relative h-[30vh] overflow-hidden">
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full bg-cover bg-center transition-opacity duration-1000 ease-in-out ${
              index === currentImageIndex ? "opacity-100" : "opacity-0"
            }`}
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        ))}
        <div className="absolute inset-0 bg-black opacity-70"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white relative z-10">
            <h1 className="text-2xl sm:text-2xl md:text-6xl font-bold mb-2 sm:mb-3">
              Welcome to Romsey Shooting Club
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl mb-4 sm:mb-6">
              A Home Office approved practical shooting club based in the heart
              of Hampshire
            </p>
          </div>
        </div>
      </div>
      <div className="bg-green-100 py-16" id="about-us">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-green-800 mb-8">
            About Us
          </h2>
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto">
            We are a vibrant club with over 100 members from all walks of life
            who meet bi-weekly to partake in Practical Shotgun and other
            shooting disciplines. We have active associations with many other
            shooting clubs throughout the UK and hold regular inter-club
            competitions.
          </p>
        </div>
      </div>

      {/* New How to Join section */}
      <div className="bg-green-50 py-16" id="how-to-join">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-green-800 mb-8">
            How to Join
          </h2>
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto mb-8">
            Due to the nature of our sport becoming a member is not as
            straightforward as other club sports.
          </p>
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto mb-8">
            Whilst membership is currently closed for new members we do
            occasionally invite people to enter membership screening throughout
            the year. If you wish to be placed onto the waiting list we ask you
            to use the Contact Us section below and introduce yourself.
          </p>
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto mb-8">
            We would kindly request you provide answers to the following
            questions:
          </p>
          <ol className="list-disc list-inside text-green-700 max-w-xl mx-auto mb-8">
            <li className="mb-2">
              Do you have any previous shooting experience?
            </li>
            <li className="mb-2">
              Are you a member of another shooting club in the UK or abroad?
            </li>
            <li className="mb-2">
              Do you currently hold a Firearms Certificate or Shotgun
              Certificate?
            </li>
            <li className="mb-2">
              What interests you about our club and the Practical Shooting
              sport?
            </li>
            <li>Where you are based?</li>
          </ol>
        </div>
      </div>

      {/* New Our Disciplines section */}
      <div className="bg-green-100 py-16" id="disciplines">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-green-800 mb-4">
            Our Disciplines
          </h2>
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto mb-12">
            Explore the various disciplines and activities offered by the Romsey
            Shooting Club:
          </p>
          <div className="flex flex-wrap justify-center gap-8">
            <DisciplineCard
              icon="🌳"
              title="Practical Shotgun"
              description="A dynamic sport where competitors aim to balance accuracy, firepower, and speed to score points quickly and precisely."
            />
            <DisciplineCard
              icon="🎯"
              title="Target Shooting"
              description="The improvement of accuracy over various distances."
            />
            <DisciplineCard
              icon="🏆"
              title="Competitions"
              description="Participate in local and national shooting competitions."
            />
          </div>
        </div>
      </div>

      {/* New Contact Us section */}
      <div className="bg-green-50 py-16" id="contact-us">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-green-800 mb-4">
            Contact Us
          </h2>
          {message.text && (
            <div
              className={`mb-4 p-3 rounded text-center ${
                message.type === "success"
                  ? "bg-green-200 text-green-800"
                  : "bg-red-200 text-red-800"
              }`}
            >
              {message.text}
            </div>
          )}
          <p className="text-lg text-center text-green-700 max-w-3xl mx-auto mb-8">
            Have questions or want to learn more? Get in touch with us!
          </p>
          <form className="max-w-md mx-auto" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your Name"
              className="w-full mb-4 p-2 border border-green-300 rounded"
              required
            />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full mb-4 p-2 border border-green-300 rounded"
              required
            />
            <textarea
              placeholder="Your Message"
              className="w-full mb-4 p-2 border border-green-300 rounded h-32"
              required
            ></textarea>
            <div className="mb-4 flex justify-center">
              <Turnstile
                sitekey={process.env.REACT_APP_CF_TURNSTILE_SITEKEY}
                onVerify={(token) => setTurnstileToken(token)}
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-700 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors disabled:bg-gray-400"
              disabled={formSubmitting || !turnstileToken}
            >
              {formSubmitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>

      {/* Add the Footer component at the end */}
      <Footer />
    </div>
  );
}

// New component for discipline cards
function DisciplineCard({ icon, title, description }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center w-64">
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-xl font-semibold text-green-800 mb-2">{title}</h3>
      <p className="text-green-700">{description}</p>
    </div>
  );
}

export default HomePage;
